import { render, staticRenderFns } from "./template.html?vue&type=template&id=87b23958&scoped=true&"
import script from "./controller.js?vue&type=script&lang=js&"
export * from "./controller.js?vue&type=script&lang=js&"
import style0 from "./styles.css?vue&type=style&index=0&id=87b23958&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b23958",
  null
  
)

export default component.exports