import slider from '../../components/slider/slider'

export default {
    name: 'home',
    components: {
        slider
    },
    data() {
        return {
            category: 1,
            info: 1
        }
    },
    methods: {
        setCategory: function(category) {
            if(this.category === category) return false;
            this.category = category;
            this.info = 1
        }
    }
}
