import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/home'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/integrify',
        name: 'integrify',
        component: () => import('../views/integrify/integrify')
    },
    {
        path: '/soluciones/:scroll?',
        name: 'about',
        component: () => import('../views/about/about')
    },
    {
        path: '/marcas',
        name: 'trademarks',
        component: () => import('../views/trademarks/trademarks')
    },
    {
        path: '/proyectos',
        name: 'projects',
        component: () => import('../views/projects/projects')
    },
    {
        path: '/productos',
        name: 'products',
        component: () => import('../views/products/products')
    },
    {
        path: '/blog/:category?',
        name: 'blog',
        component: () => import('../views/blog/blog')
    },
    {
        path: '/post/:id',
        name: 'post',
        component: () => import('../views/post/post')
    },
    {
        path: '/contacto',
        name: 'contacto',
        component: () => import('../views/contact/contact')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/privacy/privacy')
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: () => import('../views/thanks/thanks')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

export default router
