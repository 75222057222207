export default {
    name: 'slidder',
    data() {
        return {
            slide: 1,
            interval: null,
            play: false
        }
    },
    methods: {
        setSlide: async function(slide) {
            if(slide === this.slide && this.play) return this.stop();
            if(slide === this.slide && !this.play) return this.resume();
            this.slide = slide;
            clearInterval(this.interval);
            this.play = false;
            await setTimeout(null, 100);
            this.resume();
        },
        nextSlide: function() {
            this.slide = this.slide === 4? 1 : this.slide + 1;
        },
        stop: function() {
            clearInterval(this.interval);
            this.play = false;
        },
        resume: function() {
            this.interval = setInterval(this.nextSlide, 5000);
            this.play = true;
        }
    },
    mounted() {
        this.resume()
    }
}
